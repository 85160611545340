export const whitelistedPages = [
  '/',
  'logout',
  'login',
  'auth',
  'dashboard',
  'forgot_password',
  'reset_password',
  'user_announcement',
  'admin_payroll_import_howto',
];

export default {
  whitelistedPages,
};
