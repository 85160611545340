import r from '~/utils/render-utils';
import AdminLeaveRoutes from '~/router/admin/leave';
import AdminExpenseRoutes from '~/router/admin/expense';
import AdminEmployeeRoutes from '~/router/admin/employee';
import AdminDepartmentRoutes from '~/router/admin/department';
import AdminJobCategoryRoutes from '~/router/admin/job-category';
import AdminPositionRoutes from '~/router/admin/position';
import AdminSettingRoutes from '~/router/admin/setting';
import AdminAccessRoutes from '~/router/admin/access';
import AdminScheduleRoutes from '~/router/admin/schedule';
import AdminAttendanceRoutes from '~/router/admin/attendance';
import AdminPayrollRoutes from '~/router/admin/payroll';
import AdminManageCompanyRoutes from '~/router/admin/manage-company';
import AdminRecruitmentRoutes from '~/router/admin/recruitment';
import AdminBusinessTripRoutes from '~/router/admin/business-trip';

const PageAdminIndex = () => import(/* webpackChunkName: 'admin_index' */ '~/pages/admin/PageAdminIndex');
const ImportHowTo = () => import(/* webpackChunkName: 'admin_payroll_import_howto' */ '~/pages/admin/payroll/ImportHowTo');

export default [
  { path: '/admin/import/how-to', name: 'admin_payroll_import_howto', component: r(ImportHowTo) },
  {
    path: '/admin',
    component: r(PageAdminIndex),
    children: [
      { path: '', redirect: '/admin/setting', name: 'admin_index' },
      ...AdminExpenseRoutes,
      ...AdminLeaveRoutes,
      ...AdminDepartmentRoutes,
      ...AdminJobCategoryRoutes,
      ...AdminPositionRoutes,
      ...AdminEmployeeRoutes,
      ...AdminSettingRoutes,
      ...AdminAccessRoutes,
      ...AdminScheduleRoutes,
      ...AdminAttendanceRoutes,
      ...AdminPayrollRoutes,
      ...AdminManageCompanyRoutes,
      ...AdminRecruitmentRoutes,
      ...AdminBusinessTripRoutes,
    ],
  },
];
