import ApiOptions from '~/api/api-options';
import { objectToQueryString, validateObject } from '~/helpers/url-helper';

export default {
  // Approval Level
  getExpenseApprovalLevels(formData) {
    const stacks = ['limit', 'page', 'search', 'sort_by', 'sort_direction', 'noLimit'];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/expenses/approval-level${filteredQs}`, 'user');
  },
  getApprovalLevel(uuid) {
    return this.get(`/api/v1/expenses/approval-level/${uuid}`, 'user');
  },
  createApprovalLevel(formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.post(`/api/v1/expenses/approval-level`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  updateApprovalLevel(id, formData) {
    const allowed = ['*'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/expenses/approval-level/${id}`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  deleteApprovalLevel(id) {
    return this.delete(`/api/v1/expenses/approval-level/${id}`, 'user');
  },

  // Expense
  getExpenseType(queries,  customCompanyId = null) {
    const allowed = ['search', 'sort_by', 'sort_direction', 'page', 'limit', 'no_limit', 'status'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/expenses/types${filteredQs}`, 'user', new ApiOptions({ customCompanyId }));
  },
  postExpenseType(formData) {
    return this.post(`/api/v1/expenses/types`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  putExpenseType(id, formData) {
    return this.put(`/api/v1/expenses/types/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateTypeStatus(id) {
    return this.patch(`/api/v1/expenses/types/${id}/status`, 'user');
  },
  deleteExpenseType(id) {
    return this.delete(`/api/v1/expenses/types/${id}`, 'user');
  },
  getExpenseStatus(queries) {
    return this.get(`/api/v1/expenses/statuses${objectToQueryString(queries)}`, 'user');
  },
  getExpenseChart(queries) {
    const allowed = ['employee_id', 'start_date', 'end_date'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/expenses/chart${filteredQs}`, 'user');
  },

  // Expense Request
  getMyExpenseRequest(formData) {
    const stacks = [
      'limit',
      'page',
      'employee_id',
      'request_type',
      'expense_type_id',
      'expense_status_id',
      'start_date',
      'end_date',
      'search',
      'sort_by',
      'sort_direction',
      'myturn',
    ];
    const filteredQs = validateObject(formData, stacks, true);
    return this.get(`/api/v1/expenses${filteredQs}`, 'user');
  },
  getAvailableExpenseType(queries, cts = null) {
    return this.get(`/api/v1/expenses/types${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExpenseRequest(queries) {
    const allowed = [
      'limit',
      'page',
      'employee_id',
      'request_type',
      'expense_type_id',
      'expense_status_id',
      'start_date',
      'end_date',
      'search',
      'sort_by',
      'sort_direction',
      'myturn',
    ];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/expenses${filteredQs}`, 'user');
  },
  getExpenseRequestCount(queries) {
    const allowed = [
      'employee_id',
      'request_type',
      'expense_type_id',
      'expense_status_id',
      'expense_status_id_by_setting',
      'start_date',
      'end_date',
      'search',
    ];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/expenses/count${filteredQs}`, 'user');
  },
  getExpenseRequestById(id) {
    return this.get(`/api/v1/expenses/${id}`, 'user');
  },
  getExpenseRequestHistoryById(id) {
    return this.get(`/api/v1/expenses/${id}/histories`, 'user');
  },
  createExpenseRequest(formData) {
    return this.post(`/api/v1/expenses`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateExpenseRequest(id, formData) {
    return this.put(`/api/v1/expenses/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateRequestToPaid(id, formData) {
    return this.put(`/api/v1/expenses/${id}/paid`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateReceiptForm(id, formData) {
    return this.put(`/api/v1/expenses/${id}/receipt`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  setExpenseRequestStatus(id, formData) {
    const allowed = ['expense_status_id', 'expense_notes'];
    const filtered = validateObject(formData, allowed);
    return this.patch(`/api/v1/expenses/${id}/status`, 'user', new ApiOptions({ requestPayload: filtered, responseErrorJson: false }));
  },
  getAllSettings() {
    return this.get('/api/v1/expenses/settings?sort_by=name&sort_direction=asc', 'user');
  },
  getSettingValueByKey(key) {
    return this.get(`/api/v1/expenses/settings/keys/${key}`, 'user');
  },
  getMappingTransferExpense(id, period) {
    return this.get(`/api/v1/expense-mapping?employee_id=${id}&period=${period}`, 'user');
  },
};
