import ApiOptions from '../api-options';
import { objectToQueryString, serialize, validateObject } from '~/helpers/url-helper';

export default {
  getEarningTypes(queries, customCompanyId = null) {
    return this.get(`/api/v1/bonus/type${objectToQueryString(queries)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  createEarningTypes(formData) {
    return this.post(`/api/v1/bonus/type`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  updateEarningTypes(id, formData) {
    return this.put(`/api/v1/bonus/type/${id}`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  deleteEarningTypes(id) {
    return this.delete(`/api/v1/bonus/type/${id}`, 'user');
  },
  getDeductionTypes(queries, customCompanyId = null) {
    return this.get(`/api/v1/deduction/type${objectToQueryString(queries)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  createDeductionTypes(formData) {
    return this.post(`/api/v1/deduction/type`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  updateDeductionTypes(id, formData) {
    return this.put(`/api/v1/deduction/type/${id}`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  deleteDeductionTypes(id) {
    return this.delete(`/api/v1/deduction/type/${id}`, 'user');
  },
  getTaxTypes(queries, customCompanyId = null) {
    return this.get(`/api/v1/taxs${objectToQueryString(queries)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  createTaxTypes(formData) {
    return this.post(`/api/v1/taxs`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateTaxTypes(id, formData) {
    return this.put(`/api/v1/taxs/${id}`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  deleteTaxTypes(id) {
    return this.delete(`/api/v1/taxs/${id}`, 'user');
  },
  getPayPeriods() {
    return this.get(`/api/v1/pay/period`, 'user');
  },
  getPayTypes() {
    return this.get(`/api/v1/pay/type`, 'user');
  },
  getSalaryChangeReasons(queries, customCompanyId = null) {
    const finalQuery = { ...queries, reason_type: 'change' };
    return this.get(`/api/v1/reasons${objectToQueryString(finalQuery)}`, 'user', new ApiOptions({ customCompanyId }));
  },
  createSalaryChangeReasons(formData) {
    return this.post(`/api/v1/reasons?reason_type=change`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  updateSalaryChangeReasons(id, formData) {
    return this.put(`/api/v1/reasons/${id}?reason_type=change`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  deleteSalaryChangeReasons(id) {
    return this.delete(`/api/v1/reasons/${id}`, 'user');
  },
  getSalaryChangeRejectionReasons() {
    return this.get(`/api/v1/reasons?reason_type=reject`, 'user');
  },

  getPayrollPayslips(queries) {
    const allowed = ['status', 'sort_direction', 'sort_by', 'period', 'limit', 'page', 'department_id', 'search'];
    const filteredQs = validateObject(queries, allowed, true);
    return this.get(`/api/v1/payslip${filteredQs}`, 'user');
  },

  getPayrollPayslip(id) {
    return this.get(`/api/v1/payslip/${id}`, 'user');
  },

  getPayrollPayslipHistory(id) {
    return this.get(`/api/v1/payslip/${id}/history`, 'user');
  },
  publishPayrollPayslips(formData) {
    return this.post(`/api/v1/payslip/publish`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  revisePayrollPayslips(id, formData) {
    return this.put(`/api/v1/payslip/${id}/revision`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  updatePayrollPayslips(id, formData) {
    return this.put(`/api/v1/payslip/${id}`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
  createPayrollPayslips(formData) {
    return this.post('/api/v1/payslip/generate-manual', 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },

  // Export Payslip
  getExports(queries, cts = null) {
    return this.get(`/api/v1/payslip-exports${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportStatus() {
    return this.get(`/api/v1/attendances/status`, 'user');
  },
  getExportById(id) {
    return this.get(`/api/v1/payslip-exports/${id}`, 'user');
  },
  getExportEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/payslip-exports/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getExportHistoryById(id) {
    return this.get(`/api/v1/payslip-exports/${id}/history`, 'user');
  },
  exportPayslip(formData) {
    return this.post(`/api/v1/payslip-exports`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },

  // Import Payslip
  getImports(queries, cts = null) {
    return this.get(`/api/v1/payslip-imports${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getImportStatus() {
    return this.get(`/api/v1/attendances/status`, 'user');
  },
  getImportById(id) {
    return this.get(`/api/v1/payslip-imports/${id}`, 'user');
  },
  getImportEmployeesById(id, queries = '', cts = null) {
    return this.get(`/api/v1/payslip-imports/${id}/employees${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getImportHistoryById(id) {
    return this.get(`/api/v1/payslip-imports/${id}/history`, 'user');
  },
  importPayslip(formData) {
    return this.post(`/api/v1/payslip-imports`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    }));
  },

  deleteDraftPayslips(id) {
    return this.delete(`/api/v1/payslip/${id}`, 'user');
  },
  getFinanceDocs(queries, cts = null, customCompanyId) {
    return this.get(`/api/v1/finance-docs${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts, customCompanyId }));
  },
  getFinanceDocById(id) {
    return this.get(`/api/v1/finance-docs/${id}`, 'user');
  },
  getFinanceDocEmployeesById(id, queries = '') {
    return this.get(`/api/v1/finance-docs/${id}/employees${objectToQueryString(queries)}`, 'user');
  },
  createFinanceDoc(formData) {
    return this.post(`/api/v1/finance-docs`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  updateFinanceDoc(id, formData) {
    const allowed = ['document_name', 'period'];
    const filtered = validateObject(formData, allowed);
    return this.put(`/api/v1/finance-docs/${id}`, 'user', new ApiOptions({ requestPayload: formData, responseErrorJson: false }));
  },
  uploadFinanceDoc(formData) {
    const options = new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
      requestHeaders: { 'Content-Type': 'multipart/form-data' },
    });
    return this.post(`/api/v1/finance-docs/upload`, 'user', options);
  },
  deleteFinanceDoc(docId, uuid) {
    return this.delete(`/api/v1/finance-docs/${docId}/employee/${uuid}`, 'user');
  },
  sendNotifFinanceDoc(formData) {
    return this.post(`/api/v1/finance-docs/push-notification`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },

  getPayrolls(queries, cts = null) {
    return this.get(`/api/v1/employees/salaries${objectToQueryString(queries)}`, 'user', new ApiOptions({ cancelTokenSource: cts }));
  },
  getPayrollStatus() {
    return this.get(`/api/v1/salaries/status?type=salary_status`, 'user');
  },
  getPayrollHistory(id) {
    return this.get(`/api/v1/salaries/${id}/history`, 'user');
  },
  getReviseInfo(id) {
    return this.get(`/api/v1/revise/${id}`, 'user');
  },
  getSeenInfo(id) {
    return this.get(`/api/v1/salaries/${id}/seen-info`, 'user');
  },
  approvePayroll(formData) {
    return this.post(`/api/v1/salaries/approve`, 'user', new ApiOptions({
      requestPayload: formData,
      responseErrorJson: false,
    }));
  },
};
