import Vue from 'vue';
import VueCookies from 'vue-cookies';
import ApiClient from '~/api';

Vue.use(VueCookies);

const initialState = {
  histories: [],
  requestStatuses: [],
  requests: [],
  requestMeta: {},
  transports: [],
  transportMeta: {},
  settings: null,
  totalPending: 0,
};

const getters = {
  histories: state => state.histories,
  requestStatuses: state => state.requestStatuses,
  requests: state => state.requests,
  requestMeta: state => state.requestMeta,
  transports: state => state.transports,
  transportMeta: state => state.transportMeta,
  settings: state => state.settings,
  totalPending: state => state.totalPending,
};

const actions = {
  async fetchRequestStatusList({ commit }) {
    try {
      const { data } = await ApiClient.v1.trip.getBusinessTripStatus();
      commit('setRequestStatuses', data);
      return data;
    } catch (e) {
      commit('setRequestStatuses', []);
      return [];
    }
  },
  async fetchRequest({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.trip.getBusinessTripRequest(query);

      commit('setRequests', data);
      commit('setRequestMeta', meta);

      return data;
    } catch (e) {
      commit('setRequests', []);
      commit('setRequestMeta', {});
      return [];
    }
  },
  async fetchRequestById(_, { personal, id }) {
    try {
      const res = await ApiClient.v1.trip.getBusinessTripRequestById(id);
      return res;
    } catch (e) {
      // return [];
      return { errors: e, data: [] };
    }
  },
  async fetchHistory({ commit }, id) {
    try {
      const { data } = await ApiClient.v1.trip.getBusinessTripRequestHistoryById(id);
      commit('setHistories', data);
      return data;
    } catch (e) {
      commit('setHistories', []);
      return [];
    }
  },
  createRequest(_, { formData }) {
    return ApiClient.v1.trip.createBusinessTripRequest(formData);
  },
  updateRequest(_, { id, formData }) {
    return ApiClient.v1.trip.updateBusinessTripRequest(id, formData);
  },
  updateStatus(_, { id, formData }) {
    return ApiClient.v1.trip.setBusinessTripRequestStatus(id, { ...formData });
  },

  async fetchTransportList({ commit }, { query }) {
    try {
      const { data, meta } = await ApiClient.v1.trip.getBusinessTripTransport(query);

      commit('setTransports', data);
      commit('setTransportMeta', meta);
      return data;
    } catch (e) {
      commit('setTransports', []);
      commit('setTransportMeta', []);
      return [];
    }
  },
  async fetchSetting({ commit, state }) {
    try {
      const { data } = await ApiClient.v1.trip.getSettings();
      commit('setSettings', data);
      return data;
    } catch (e) {
      commit('setSettings', []);
      return [];
    }
  },
  async saveSetting({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.trip.saveSettings(formData);
      return data;
    } catch (e) {
      const caption = 'Failed to Save Business Travel Setting';
      dispatch('error/catchError', { error: e, caption }, { root: true });

      return [];
    }
  },
  async fetchRequestCount({ commit }) {
    try {
      const { data } = await ApiClient.v1.trip.getBusinessTripRequestCount();
      commit('setTotalPending', data);

      return [];
    } catch (e) {
      commit('setTotalPending', 0);
      return [];
    }
  },
};

const mutations = {
  setHistories(state, value) {
    state.histories = value;
  },
  setRequestStatuses(state, value) {
    state.requestStatuses = value;
  },
  setRequests(state, value) {
    state.requests = value;
  },
  setRequestMeta(state, value) {
    state.requestMeta = value;
  },
  setTransports(state, value) {
    state.transports = value;
  },
  setTransportMeta(state, value) {
    state.transportMeta = value;
  },
  setSettings(state, value) {
    state.settings = value;
  },
  setTotalPending(state, value) {
    state.totalPending = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
