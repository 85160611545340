import r from '~/utils/render-utils';

const PageAdminPayrollIndex = () => import(/* webpackChunkName: 'admin_payroll_index' */ '~/pages/admin/payroll/Index');
const PageAdminPayrollPayslipIndex = () => import(/* webpackChunkName: 'admin_payroll_payslip_index' */ '~/pages/admin/payroll/payslip/Index');
const PageAdminPayrollPayslipUpdate = () => import(/* webpackChunkName: 'admin_payroll_payslip_update' */ 'pages/admin/payroll/payslip/Update');
const PageAdminPayrollPayslipRevise = () => import(/* webpackChunkName: 'admin_payroll_payslip_revise' */ 'pages/admin/payroll/payslip/Revise');
const PageAdminPayrollPayslipCreate = () => import(/* webpackChunkName: 'admin_payroll_payslip_create' */ 'pages/admin/payroll/payslip/Create');
const PageAdminPayrollPayslipListIndex = () => import(/* webpackChunkName: 'admin_payroll_payslip_list_index' */ 'pages/admin/payroll/payslip/list/Index');
const PageAdminPayrollPayslipListDraft = () => import(/* webpackChunkName: 'admin_payroll_payslip_list_draft' */ 'pages/admin/payroll/payslip/list/Draft');
const PageAdminPayrollPayslipListPublished = () => import(/* webpackChunkName: 'admin_payroll_payslip_list_published' */ 'pages/admin/payroll/payslip/list/Published');

const ExportIndex = () => import(/* webpackChunkName: 'admin_payroll_export_index' */ '~/pages/admin/payroll/ExportIndex');
const ExportList = () => import(/* webpackChunkName: 'admin_payroll_export_list' */ '~/pages/admin/payroll/ExportList');
const ExportDetail = () => import(/* webpackChunkName: 'admin_payroll_export_detail' */ '~/pages/admin/payroll/ExportDetail');

const ImportIndex = () => import(/* webpackChunkName: 'admin_payroll_import_index' */ '~/pages/admin/payroll/ImportIndex');
const ImportList = () => import(/* webpackChunkName: 'admin_payroll_import_list' */ '~/pages/admin/payroll/ImportList');
const ImportDetail = () => import(/* webpackChunkName: 'admin_payroll_import_detail' */ '~/pages/admin/payroll/ImportDetail');

const FinanceDocIndex = () => import(/* webpackChunkName: 'admin_payroll_finance_doc_index' */ '~/pages/admin/payroll/FinanceDocIndex');
const FinanceDocList = () => import(/* webpackChunkName: 'admin_payroll_finance_doc_list' */ '~/pages/admin/payroll/FinanceDocList');
const FinanceDocDetail = () => import(/* webpackChunkName: 'admin_payroll_finance_doc_detail' */ '~/pages/admin/payroll/FinanceDocDetail');

const FixedAllowanceIndex = () => import(/* webpackChunkName: 'admin_setting_payroll_fixed_allowance_index' */ 'pages/admin/payroll/AllowanceIndex');
const FixedAllowanceList = () => import(/* webpackChunkName: 'admin_setting_payroll_fixed_allowance_list' */ 'pages/admin/payroll/AllowanceList');
const DeductionsIndex = () => import(/* webpackChunkName: 'admin_setting_payroll_deductions_index' */ 'pages/admin/payroll/DeductionIndex');
const DeductionsList = () => import(/* webpackChunkName: 'admin_setting_payroll_deductions_list' */ 'pages/admin/payroll/DeductionList');
const TaxesIndex = () => import(/* webpackChunkName: 'admin_setting_payroll_taxes_index' */ 'pages/admin/payroll/TaxIndex');
const TaxesList = () => import(/* webpackChunkName: 'admin_setting_payroll_taxes_list' */ 'pages/admin/payroll/TaxList');
const SalaryReasonIndex = () => import(/* webpackChunkName: 'admin_setting_payroll_new_salary_reason_index' */ 'pages/admin/payroll/SalaryReasonIndex');
const SalaryReasonList = () => import(/* webpackChunkName: 'admin_setting_payroll_new_salary_reason_list' */ 'pages/admin/payroll/SalaryReasonList');

const PayrollIndex = () => import(/* webpackChunkName: 'admin_payroll_index' */ '~/pages/admin/payroll/PayrollIndex');
const PayrollList = () => import(/* webpackChunkName: 'admin_payroll_list' */ '~/pages/admin/payroll/PayrollList');
const PayrollRevise = () => import(/* webpackChunkName: 'admin_payroll_revise' */ '~/pages/admin/payroll/PayrollRevise');
const PayrollView = () => import(/* webpackChunkName: 'admin_payroll_view' */ '~/pages/admin/payroll/PayrollView');
const PayrollHistory = () => import(/* webpackChunkName: 'admin_payroll_history' */ '~/pages/admin/payroll/PayrollHistory');

const routes = {
  path: 'payroll',
  component: r(PageAdminPayrollIndex),
  children: [
    {
      path: '',
      redirect: '/admin/payroll/payslip',
      name: 'admin_payroll_payslip_index',
    },
    {
      path: 'list',
      component: r(PayrollIndex),
      children: [
        {
          path: '',
          component: r(PayrollList),
          name: 'admin_payroll_list',
        },
        {
          path: ':id/revise/:fid',
          component: r(PayrollRevise),
          name: 'admin_payroll_revise',
        },
        {
          path: ':id/view/:fid',
          component: r(PayrollView),
          name: 'admin_payroll_view',
        },
        {
          path: ':id/history/:fid',
          component: r(PayrollHistory),
          name: 'admin_payroll_history',
        },
      ],
    },
    {
      path: 'payslip',
      component: r(PageAdminPayrollPayslipIndex),
      children: [
        {
          path: '',
          redirect: '/admin/payroll/payslip/list/draft',
          name: 'admin_payroll_payslip_list',
        },
        {
          path: ':id(\\d+)',
          component: r(PageAdminPayrollPayslipIndex),
          children: [
            {
              path: '',
              redirect: '/admin/payroll/payslip/list/draft',
              name: 'admin_payroll_payslip_detail',
            },
            {
              path: 'update',
              component: r(PageAdminPayrollPayslipUpdate),
              name: 'admin_payroll_payslip_update',
            },
            {
              path: 'revise',
              component: r(PageAdminPayrollPayslipRevise),
              name: 'admin_payroll_payslip_revise',
            },
          ],
        },
        {
          path: ':uuid/create',
          component: r(PageAdminPayrollPayslipCreate),
          name: 'admin_payroll_payslip_create',
        },
        {
          path: 'list',
          component: r(PageAdminPayrollPayslipListIndex),
          children: [
            {
              path: '',
              redirect: '/admin/payroll/payslip/list/draft',
              name: 'admin_payroll_payslip_list_index',
            },
            {
              path: 'draft',
              component: r(PageAdminPayrollPayslipListDraft),
              name: 'admin_payroll_payslip_list_draft',
            },
            {
              path: 'published',
              component: r(PageAdminPayrollPayslipListPublished),
              name: 'admin_payroll_payslip_list_published',
            },
          ],
        },
      ],
    },
    {
      path: 'export',
      component: r(ExportIndex),
      children: [
        { path: '', name: 'admin_payroll_export_list', component: r(ExportList) },
        { path: ':id', name: 'admin_payroll_export_detail', component: r(ExportDetail) },
      ],
    },
    {
      path: 'import',
      component: r(ImportIndex),
      children: [
        { path: '', name: 'admin_payroll_import_list', component: r(ImportList) },
        { path: ':id', name: 'admin_payroll_import_detail', component: r(ImportDetail) },
      ],
    },
    {
      path: 'finance-doc',
      component: r(FinanceDocIndex),
      children: [
        { path: '', name: 'admin_payroll_finance_doc_list', component: r(FinanceDocList) },
        { path: ':id', name: 'admin_payroll_finance_doc_detail', component: r(FinanceDocDetail) },
      ],
    },
    {
      path: 'fixed-allowance',
      component: r(FixedAllowanceIndex),
      children: [
        {
          path: '',
          component: r(FixedAllowanceList),
          name: 'admin_setting_payroll_fixed_allowance_list',
        },
      ],
    },
    {
      path: 'deductions',
      component: r(DeductionsIndex),
      children: [
        { path: '', component: r(DeductionsList), name: 'admin_setting_payroll_deductions_list' },
      ],
    },
    {
      path: 'taxes',
      component: r(TaxesIndex),
      children: [
        { path: '', component: r(TaxesList), name: 'admin_setting_payroll_taxes_list' },
      ],
    },
    {
      path: 'salary-reason',
      component: r(SalaryReasonIndex),
      children: [
        {
          path: '',
          component: r(SalaryReasonList),
          name: 'admin_setting_payroll_new_salary_reason_list',
        },
        {
          path: 'new',
          name: 'admin_setting_payroll_new_salary_reason_new',
        },
      ],
    },
  ],
};

// old routes
const redirect = {
  path: 'setting/payroll',
  children: [
    { path: 'fixed-allowance', name: 'admin_setting_payroll_fixed_allowance_list_old', redirect: { name: 'admin_setting_payroll_fixed_allowance_list' } },
    { path: 'deductions', name: 'admin_setting_payroll_deductions_list_old', redirect: { name: 'admin_setting_payroll_deductions_list' } },
    { path: 'taxes', name: 'admin_setting_payroll_taxes_list_old', redirect: { name: 'admin_setting_payroll_taxes_list' } },
    {
      path: 'new-salary-reason',
      children: [
        { path: '', name: 'admin_setting_payroll_new_salary_reason_list_old', redirect: { name: 'admin_setting_payroll_new_salary_reason_list' } },
        { path: 'new', name: 'admin_setting_payroll_new_salary_reason_new_old', redirect: { name: 'admin_setting_payroll_new_salary_reason_new' } },
      ],
    },
  ],
};

export default [
  routes,
  redirect,
];
