import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6d71509a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CSidebar: require('/home/web/alpha-fe/components/navbar/CSidebar.vue').default,CTopBar: require('/home/web/alpha-fe/components/navbar/CTopBar.vue').default,CToast2: require('/home/web/alpha-fe/components/toast/CToast2.vue').default,CFooter: require('/home/web/alpha-fe/components/CFooter.vue').default})
