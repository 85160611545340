//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CValidationError',
  props: {
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
    keys: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
